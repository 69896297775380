import ModalSearchSavingAccount from "../../../components/modal-search-saving-account";
export default {
  name: "TSavingAccountBalanceInquiry",
  components: {
    ModalSearchSavingAccount,
  },
  data() {
    return {
      property: {
        filterDto: {
          accountNumber: 0,
        },
        modal: {
          showModalCheckingSavingAccount: false,
        },
        animation: {
          searchBalanceInquiry: {
            isLoading: false,
          },
          historyBalanceInquiry: {
            isLoading: false,
          },
        },
      },
      form: {
        balanceInquiry: {
          accountNumber: 0,
          name: "",
          balanceEnd: 0,
          balanceMinimum: 0,
          holdNominal: 0,
          activeBalance: 0,
        },
      },
    };
  },
  methods: {
    async searchSavingAccountBalanceInquiry(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/balanceInquiry/" + props.row.accountNumber,
        });
        if (resp.data.code === "SUCCESS") {
          this.form.balanceInquiry.accountNumber = resp.data.data.accountNumber;
          this.form.balanceInquiry.name = resp.data.data.qqName
            ? `${resp.data.data.name} ${props.row.qqCode} ${resp.data.data.qqName}`
            : resp.data.data.name;
          this.form.balanceInquiry.balanceEnd = resp.data.data.balanceEnd;
          this.form.balanceInquiry.balanceMinimum =
            resp.data.data.balanceMinimum;
          this.form.balanceInquiry.holdNominal = resp.data.data.holdNominal
            ? resp.data.data.holdNominal
            : 0;
          this.form.balanceInquiry.activeBalance = resp.data.data.activeBalance;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async routeToPageAccountStatement() {
      this.property.animation.historyBalanceInquiry.isLoading = true;
      await this.simpleWait(1000);
      this.property.animation.historyBalanceInquiry.isLoading = false;
      sessionStorage.setItem(
        "ACCOUNT_NUMBER_FROM_BALANCE_INQUIRY",
        this.form.balanceInquiry.accountNumber
      );
      this.$router.push("/saving/account-statement");
    },
  },
};
